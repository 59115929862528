import React from "react"
import { createIcon } from "@chakra-ui/icons"

export const TallyT = createIcon({
  displayName: "TallyT",
  viewBox: "0 0 472 992",
  path: (
    <path
      d="M2.83115 4.42024L607.789 346.578V588.112L416.272 479.784L412.425 477.607V482.028V987.54L198.196 866.281V357.963V356.459L196.887 355.718L2.83115 245.954V4.42024Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  ),
})
