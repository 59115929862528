import type { ReactNode } from "react"
import React from "react"
import type { TextProps } from "@chakra-ui/react"

import GradientTitle from "marketing/components/GradientTitle"

export default function SectionTitle({
  children,
  ...textProps
}: { children: ReactNode } & TextProps) {
  return (
    <GradientTitle
      as="h3"
      fontSize={{ base: "24px", lg: "32px" }}
      fontWeight="bold"
      lineHeight={{ base: "32px", lg: "40px" }}
      mb={{ base: 4, lg: 5 }}
      mt={{ base: 14, lg: 28 }}
      textAlign="center"
      zIndex={1}
      {...textProps}
    >
      {children}
    </GradientTitle>
  )
}
