import type { ReactNode } from "react"
import React from "react"
import type { FlexProps } from "@chakra-ui/react"
import { Flex, Box } from "@chakra-ui/react"

export default function Card({
  children,
  gridColumn,
  className,
  ...flexProps
}: { children: ReactNode } & FlexProps) {
  return (
    <Box
      background="linear-gradient(to bottom, hsla(255, 66%, 87%, 1), hsla(255, 100%, 95%, 1))"
      className={className}
      gridColumn={gridColumn}
      padding="1px"
      rounded="10px"
      w="full"
      zIndex={1}
    >
      <Flex
        background="white"
        h="full"
        p={{ base: 5, lg: 8 }}
        position="relative"
        rounded="10px"
        {...flexProps}
      >
        {children}
      </Flex>
    </Box>
  )
}
