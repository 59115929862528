import type { FC } from "react"
import React from "react"
import type { ContainerProps } from "@chakra-ui/react"
import { Container as ChakraContainer } from "@chakra-ui/react"

const HomeLayoutContainer: FC<ContainerProps> = ({
  children,
  ...containerProps
}) => {
  return (
    <ChakraContainer
      centerContent
      alignItems="stretch"
      as="main"
      display="flex"
      flex={1}
      flexDir="column"
      maxW="6xl"
      px={{ base: 4, lg: 0 }}
      {...containerProps}
    >
      {children}
    </ChakraContainer>
  )
}

export default HomeLayoutContainer
