import type { ReactNode } from "react"
import React from "react"
import type { TextProps } from "@chakra-ui/react"
import { Text } from "@chakra-ui/react"

export default function GradientTitle({
  children,
  ...textProps
}: { children: ReactNode } & TextProps) {
  return (
    <Text
      as="h1"
      backgroundClip="text"
      bg="linear-gradient(90deg, #725BFF 5.94%, #6E40CF 108.29%)"
      className="background-clip-text"
      color="transparent"
      fontFamily="Plus Jakarta Sans"
      fontSize={{ base: "32px", lg: "68px" }}
      fontWeight="extrabold"
      lineHeight={{ base: "40px", lg: "86px" }}
      textAlign="center"
      {...textProps}
    >
      {children}
    </Text>
  )
}
