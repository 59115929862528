import type { ReactNode } from "react"
import React from "react"
import { Stack, Text, Icon } from "@chakra-ui/react"

import Link from "common/components/Link"
import { ArrowRight } from "ui/components/icons/font-awesome/ArrowRight"

export function CardPurpleLink({
  children,
  href,
  isExternal = false,
}: {
  children: ReactNode
  href: string
  isExternal?: boolean
}) {
  return (
    <Link
      className="no-underline"
      href={href}
      isExternal={isExternal}
      w="max-content"
    >
      <Stack align="center" direction="row" pt={3} spacing={3}>
        <Text
          color="primary.500"
          fontSize={{ base: "16px", lg: "18px" }}
          fontWeight={{ base: "medium", lg: "bold" }}
          lineHeight={{ base: "24px", lg: "28px" }}
        >
          {children}
        </Text>
        <Icon
          as={ArrowRight}
          fill="primary.500"
          h={{ base: 4, lg: 5 }}
          w={{ base: 4, lg: 5 }}
        />
      </Stack>
    </Link>
  )
}
