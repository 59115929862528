import React from "react"
import { createIcon } from "@chakra-ui/icons"

const Compound = createIcon({
  displayName: "Compound ",
  viewBox: "0 0 90 90",
  path: (
    <g>
      <g filter="url(#filter0_d_1620_209)">
        <circle cx="45" cy="44" fill="black" r="42" />
        <path
          clipRule="evenodd"
          d="M27.2632 58.0826C26.0074 57.314 25.2388 55.949 25.2388 54.479V46.2722C25.2388 45.2978 26.0326 44.5082 27.007 44.5124C27.3178 44.5124 27.6244 44.5964 27.8932 44.7518L46.411 55.55C47.4946 56.18 48.1624 57.3392 48.1624 58.595V67.0958C48.1666 68.2634 47.2216 69.2126 46.054 69.2126C45.6634 69.2126 45.277 69.1034 44.9452 68.9018L27.2632 58.0826ZM54.8656 42.5048C55.9492 43.1348 56.6128 44.2982 56.617 45.5498V62.8034C56.617 63.3116 56.344 63.782 55.8988 64.0298L51.8458 66.3104C51.7954 66.3398 51.7408 66.3608 51.682 66.3776V56.7974C51.682 55.5584 51.031 54.4076 49.9642 53.7692L33.7018 44.042V33.227C33.7018 32.2526 34.4956 31.463 35.47 31.4672C35.7808 31.4672 36.0874 31.5512 36.3562 31.7066L54.8656 42.5048ZM62.9716 29.762C64.0594 30.392 64.7272 31.5554 64.7272 32.8112V58.0112C64.723 58.5278 64.4374 59.0024 63.9838 59.2502L60.1408 61.325V43.7816C60.1408 42.5426 59.4898 41.396 58.4314 40.7576L41.8078 30.7868V20.5304C41.8078 20.2196 41.8918 19.913 42.043 19.6442C42.5344 18.8042 43.6138 18.5186 44.4538 19.0058L62.9716 29.762Z"
          fill="#00D395"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="90"
          id="filter0_d_1620_209"
          width="90"
          x="0"
          y="0"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.129412 0 0 0 0 0.105882 0 0 0 0 0.305882 0 0 0 0.15 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_1620_209"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1620_209"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </g>
  ),
})

// eslint-disable-next-line import/no-unused-modules
export default Compound
