import type { ReactNode } from "react"
import React from "react"
import type {
  BoxProps,
  FlexProps,
  StackProps,
  TextProps,
} from "@chakra-ui/react"
import {
  createIcon,
  Grid,
  Stack,
  Flex,
  Text,
  Icon,
  Button,
  Box,
  useDisclosure,
  Image,
  GridItem,
} from "@chakra-ui/react"
import type { GetServerSideProps } from "next"

import { CardPurpleLink } from "marketing/components/CardPurpleLink"
import SectionTitle from "marketing/components/SectionTitle"
import Card from "marketing/components/Card"
import MainLayout from "ui/layouts/main/MainLayout"
import MainLayoutHead from "ui/layouts/main/MainLayoutHead"
import MainLayoutFooter from "ui/layouts/main/MainLayoutFooter"
import { TALLY_DEFAULT_SOCIAL_PREVIEW } from "common/constants/logo"
import EthereumNetwork from "ui/components/icons/EthereumNetwork"
import { EXTERNAL_ROUTES, ROUTES } from "common/constants/routes"
import Link from "common/components/Link"
import { ArrowRight } from "ui/components/icons/font-awesome/ArrowRight"
import { Code } from "ui/components/icons/font-awesome/Code"
import WalletSelectionModal from "web3/components/WalletSelectionModal"
import { Usdc } from "web3/components/icons/Usdc"
import { TallyT } from "ui/components/icons/TallyT"
import ClientOnly from "common/components/ClientOnly"
import Compound from "ui/components/icons/governors/Compound"
import HomeLayoutContainer from "ui/layouts/home/HomeLayoutContainer"
import { isWhitelabel } from "whitelabel/hooks/useIsWhiteLabel"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { WHITELABEL_GOVERNORS_IDS } from "whitelabel/constants/governorId"
import GradientTitle from "marketing/components/GradientTitle"
import MainLayoutHeader from "ui/layouts/main/MainLayoutHeader"

function Home() {
  const walletSelectionDisclousure = useDisclosure()

  const preview = {
    title: "Tally | Start, join, and grow DAOs",
    image: TALLY_DEFAULT_SOCIAL_PREVIEW,
    description:
      "Tally is a DAO operations platform. DAOs use Tally to create and pass proposals, enable delegation, and power voting.",
  }

  return (
    <MainLayout
      bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 3%, rgba(114, 91, 255, 0.04) 78.02%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    "
    >
      <MainLayoutHead preview={preview} title="Tally - Run DAOs onchain" />
      <MainLayoutHeader displayDashboardButton displayConnectButtons={false} />
      <HomeLayoutContainer alignItems="center" pt={{ base: 6, lg: 20 }}>
        <Box
          height={{ base: "202px", lg: "214px" }}
          overflow="hidden"
          position="relative"
          pt={{ base: 6, lg: 12 }}
        >
          <Box
            bg="hsla(256, 100%, 98%, 1)"
            className="governance-card-animation"
            overflow="hidden"
          >
            <GovernanceHeader type={GovernanceHeaderType.Compound} />
            <Box bg="white" h="8px" w="full" />
            <GovernanceHeader type={GovernanceHeaderType.Ens} />
            <Box bg="white" h="8px" w="full" />
            <GovernanceHeader type={GovernanceHeaderType.Arbitrum} />
            <Box bg="white" h="8px" w="full" />
            <GovernanceHeader type={GovernanceHeaderType.Gitcoin} />
            <Box bg="white" h="8px" w="full" />
            <GovernanceHeader type={GovernanceHeaderType.Uniswap} />
            <Box bg="white" h="8px" w="full" />
            <GovernanceHeader type={GovernanceHeaderType.Hop} />
          </Box>
          <FadeMask
            bottom={0}
            gradientColor="white"
            h={{ base: "178px", lg: "166px" }}
            insetX="unset"
            top={{ base: 6, lg: 12 }}
            w="full"
            zIndex={2}
          />
        </Box>
        <GradientTitle mt={6}>Run DAOs onchain</GradientTitle>
        <Text
          color="neutral.600"
          fontSize={{ base: "18px", lg: "2xl" }}
          lineHeight={{ base: "32px", lg: "32px" }}
          mt={{ base: 0, lg: 8 }}
          textAlign="center"
        >
          Tally powers L2s, protocols, treasuries and public goods
        </Text>
        <Link href={ROUTES.explore()}>
          <Button
            data-qa="index-exploreDAOs-btn"
            mt={8}
            size="lg"
            variant="primary"
          >
            Explore DAOs
          </Button>
        </Link>
        <Grid
          background="white"
          border="1px solid hsla(255, 100%, 92%, 1)"
          height={{ base: "unset", lg: "8.25em" }}
          mt={{ base: 10, lg: 28 }}
          rounded="8px"
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }}
          zIndex={1}
        >
          <Stat
            borderBottom={{
              base: "1px solid hsla(255, 100%, 92%, 1)",
              lg: "none",
            }}
            borderRight="1px solid hsla(255, 100%, 92%, 1)"
          >
            <StatValue>$30B+</StatValue>
            <StatTitle>DAO Assets</StatTitle>
          </Stat>
          <Stat
            borderBottom={{
              base: "1px solid hsla(255, 100%, 92%, 1)",
              lg: "none",
            }}
            borderRight={{
              base: "none",
              lg: "1px solid hsla(255, 100%, 92%, 1)",
            }}
          >
            <StatValue>$600M+</StatValue>
            <StatTitle>Transferred</StatTitle>
          </Stat>
          <Stat borderRight="1px solid hsla(255, 100%, 92%, 1)">
            <StatValue>7K+</StatValue>
            <StatTitle>Proposals</StatTitle>
          </Stat>
          <Stat>
            <StatValue>200K+</StatValue>
            <StatTitle>Delegations</StatTitle>
          </Stat>
        </Grid>

        <SectionTitle>The complete DAO Operating System</SectionTitle>
        <Grid
          gap={5}
          templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
          templateRows={{ base: "repeat(2, 1fr)", lg: "max-content" }}
          w="full"
        >
          <Card>
            <Stack spacing={1}>
              <CardTitle>Launch a DAO</CardTitle>
              <CardParagraph>
                A turn key solution to launch your DAO: build your community,
                get delegates, and distribute governance tokens to the voters
                who will run the protocol.
              </CardParagraph>
              <CardPurpleLink
                isExternal
                href={EXTERNAL_ROUTES.tally.docs.daoLauncher()}
              >
                Learn More
              </CardPurpleLink>
            </Stack>
          </Card>
          <Card>
            <Stack spacing={1}>
              <CardTitle>Elect a Council</CardTitle>
              <CardParagraph flex={1}>
                Who&rsquo;s on the multisig? With Council Elections, DAOs can
                elect and manage the signers on Security Councils, Grant
                Programs and more.
              </CardParagraph>
              <CardPurpleLink
                isExternal
                href={EXTERNAL_ROUTES.tally.docs.councilElections()}
              >
                Learn More
              </CardPurpleLink>
            </Stack>
          </Card>
          <Card>
            <Stack spacing={1}>
              <CardTitle>Create Proposals</CardTitle>
              <CardParagraph flex={1}>
                DAOs on Tally have created proposals to upgrade their protocols,
                fund grant programs and much more.
              </CardParagraph>
              <CardPurpleLink
                isExternal
                href={EXTERNAL_ROUTES.tally.docs.creatingProposals()}
              >
                Learn more
              </CardPurpleLink>
            </Stack>
          </Card>
          <Card>
            <Stack spacing={1}>
              <CardTitle>Manage a Treasury</CardTitle>
              <CardParagraph flex={1}>
                With Tally Swaps your DAO can now swap assets directly from the
                treasury with MEV protected, trustless on chain swaps.
              </CardParagraph>
              <CardPurpleLink
                isExternal
                href={EXTERNAL_ROUTES.tally.docs.swaps()}
              >
                Learn more
              </CardPurpleLink>
            </Stack>
          </Card>
        </Grid>

        <SectionTitle>
          Designed for operators, voters and delegates
        </SectionTitle>
        <Grid
          gap={5}
          templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
          templateRows={{ base: "auto", lg: "133px, 283px" }}
        >
          <Funding
            gridColumn={{ base: "span 1", lg: "span 2" }}
            minH={{ base: "216px", lg: "auto" }}
          />
          <Delegate />
          <Assets />
        </Grid>

        <SectionTitle>Trusted By 500+ DAOs</SectionTitle>
        <Box>
          <Grid
            alignItems="center"
            gap={8}
            justifyContent="center"
            pt={10}
            templateColumns={{
              base: "repeat(3, 1fr)",
              md: "repeat(4, 1fr)",
              lg: "repeat(11, 1fr)",
            }}
            w="full"
          >
            {DAO_LOGOS.map((daoLogo) => (
              <GridItem key={daoLogo.key}>{daoLogo}</GridItem>
            ))}
          </Grid>
        </Box>

        <SectionTitle>Why onchain?</SectionTitle>
        <Grid
          gap={5}
          templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
          templateRows={{ base: "repeat(2, 1fr)", lg: "max-content" }}
          w="full"
        >
          <Card>
            <Stack spacing={1}>
              <CardTitle>Not all DAOs are created equal</CardTitle>
              <CardParagraph>
                With onchain DAOs, voters don’t need to trust the core team to
                honor the results of a vote. Smart contracts automatically
                implement successful proposals
              </CardParagraph>
              <CardPurpleLink
                isExternal
                href={EXTERNAL_ROUTES.tally.docs.onChainVsOffChain()}
              >
                Offchain vs Onchain
              </CardPurpleLink>
            </Stack>
          </Card>
          <Card>
            <Stack spacing={1}>
              <CardTitle>Real Power</CardTitle>
              <CardParagraph flex={1}>
                Control protocol parameters, funding decisions, software
                upgrades, and more. The power lies with voters
              </CardParagraph>
              <CardPurpleLink
                isExternal
                href={EXTERNAL_ROUTES.tally.docs.knowledgeBase()}
              >
                Read more
              </CardPurpleLink>
            </Stack>
          </Card>
        </Grid>

        <SectionTitle>Building a DAO?</SectionTitle>
        <Card align="center" direction={{ base: "column", lg: "row" }}>
          <Icon
            as={Code}
            fill="neutral.900"
            h={6}
            mb={{ base: 3, lg: 0 }}
            mr={{ base: 0, lg: 8 }}
            w={8}
          />
          <CardParagraph
            flex={{ base: "unset", lg: 1 }}
            mb={{ base: 6, lg: 0 }}
          >
            Tally is a plug-and-play UI and API for standard DAO governance
            contracts
          </CardParagraph>
          <Link href={ROUTES.developers()} width={{ base: "100%", md: "auto" }}>
            <Button
              data-qa="buildingdao-learnmore-btn"
              variant="primary"
              width="100%"
            >
              Learn more
            </Button>
          </Link>
        </Card>
        <WalletSelectionModal
          isOpen={walletSelectionDisclousure.isOpen}
          onClose={() => walletSelectionDisclousure.onClose()}
        />
        <Icon
          as={TallyT}
          color="primary.50"
          fill="transparent"
          h="900px"
          position="absolute"
          right={0}
          top="20%"
          w={{ base: "300px", lg: "600px" }}
          zIndex={0}
        />
        <Icon
          as={TallyT}
          color="primary.50"
          fill="transparent"
          h="900px"
          position="absolute"
          right={{ base: 0, lg: "-20px" }}
          top="calc(20% + 60px)"
          w={{ base: "300px", lg: "600px" }}
          zIndex={0}
        />
        <Icon
          as={TallyT}
          color="primary.50"
          fill="transparent"
          h="900px"
          position="absolute"
          right={{ base: 0, lg: "-40px" }}
          top="calc(20% + 120px)"
          w={{ base: "300px", lg: "600px" }}
          zIndex={0}
        />

        <SectionTitle>Backed by great firms</SectionTitle>
        <Box>
          <Grid
            alignItems="center"
            gap={{ base: 4, md: 20 }}
            justifyContent="center"
            pt={10}
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(4, 1fr)",
            }}
            templateRows={{ base: "repeat(8, 1fr)", md: "auto" }}
            w="full"
          >
            {BACKERS.map((backer) => (
              <GridItem key={backer.key}>{backer}</GridItem>
            ))}
          </Grid>
        </Box>
      </HomeLayoutContainer>
      <MainLayoutFooter mt={{ base: "64px", lg: 20 }} />
      <ClientOnly>
        <ScrollSetter />
      </ClientOnly>
    </MainLayout>
  )
}

function CardTitle({ children }: { children: ReactNode }) {
  return (
    <Text
      color="neutral.700"
      fontSize={{ base: "18px", lg: "20px" }}
      fontWeight="bold"
    >
      {children}
    </Text>
  )
}

function CardParagraph({
  children,
  ...textProps
}: { children: ReactNode } & TextProps) {
  return (
    <Text
      color="neutral.600"
      flex={1}
      fontSize={{ base: "16px", lg: "18px" }}
      lineHeight={{ base: "24px", lg: "28px" }}
      {...textProps}
    >
      {children}
    </Text>
  )
}

function Assets() {
  return (
    <Card direction="column" height="283px" overflow="hidden">
      <Stack spacing={1}>
        <CardTitle>See DAO assets</CardTitle>
        <CardParagraph>
          Real-time overview of DAO assets at current market value
        </CardParagraph>
      </Stack>
      <Stack
        align="center"
        bottom={0}
        direction="row"
        position="absolute"
        right={{ base: 3, lg: 22 }}
      >
        <Flex
          background="primary.50"
          borderTop="1px solid hsla(255, 100%, 92%, 1)"
          borderX="1px solid hsla(255, 100%, 92%, 1)"
          direction="column"
          height={{ base: "142px", lg: "142px" }}
          roundedTop="10px"
          w={{ base: "300px", lg: "400px" }}
        >
          <Flex align="center" justify="space-between" p={3}>
            <Text
              color="primary.400"
              fontSize="10px"
              fontWeight="bold"
              lineHeight="14px"
            >
              DAO Assets
            </Text>
            <Text
              color="primary.400"
              fontSize="12px"
              fontWeight="medium"
              lineHeight="16px"
            >
              $12,262,910 USD
            </Text>
          </Flex>
          <Grid
            gridTemplateColumns="repeat(3, 1fr)"
            gridTemplateRows="16px, repeat(3, 40px)"
          >
            <Text
              borderY="1px solid hsla(255, 100%, 92%, 1)"
              color="primary.400"
              fontSize="8px"
              lineHeight="10px"
              pl={3}
              py={2}
            >
              Assets
            </Text>
            <Text
              borderY="1px solid hsla(255, 100%, 92%, 1)"
              color="primary.400"
              fontSize="8px"
              lineHeight="10px"
              py={2}
            >
              Balance
            </Text>
            <Text
              borderY="1px solid hsla(255, 100%, 92%, 1)"
              color="primary.400"
              fontSize="8px"
              lineHeight="10px"
              pr={3}
              py={2}
            >
              Value
            </Text>
            <Flex
              align="center"
              borderBottom="1px solid hsla(255, 100%, 92%, 1)"
              pl={3}
              py={2}
            >
              <Icon as={EthereumNetwork} h={3} mr={1} w={3} />
              <Text
                color="primary.400"
                fontSize="10px"
                fontWeight="medium"
                lineHeight="20px"
              >
                Ether
              </Text>
            </Flex>
            <Text
              borderBottom="1px solid hsla(255, 100%, 92%, 1)"
              color="primary.400"
              fontSize="10px"
              fontWeight="medium"
              lineHeight="20px"
              py={2}
            >
              5.43K ETH
            </Text>
            <Text
              borderBottom="1px solid hsla(255, 100%, 92%, 1)"
              color="primary.400"
              fontSize="10px"
              fontWeight="medium"
              lineHeight="20px"
              py={2}
            >
              $8.78M USD
            </Text>
            <Flex
              align="center"
              borderBottom="1px solid hsla(255, 100%, 92%, 1)"
              pl={3}
              py={2}
            >
              <Icon as={Usdc} h={3} mr={1} w={3} />
              <Text
                color="primary.400"
                fontSize="10px"
                fontWeight="medium"
                lineHeight="20px"
              >
                USD Coin
              </Text>
            </Flex>
            <Text
              borderBottom="1px solid hsla(255, 100%, 92%, 1)"
              color="primary.400"
              fontSize="10px"
              fontWeight="medium"
              lineHeight="20px"
              py={2}
            >
              2.09M USDC
            </Text>
            <Text
              borderBottom="1px solid hsla(255, 100%, 92%, 1)"
              color="primary.400"
              fontSize="10px"
              fontWeight="medium"
              lineHeight="20px"
              py={2}
            >
              $2.09M USD
            </Text>
          </Grid>
        </Flex>
        <FadeMask
          gradientColor="hsla(256, 100%, 98%, 1)"
          height={{ base: "143px", lg: "143px" }}
        />
      </Stack>
    </Card>
  )
}

function Delegate() {
  return (
    <Card direction="column" height="283px" overflow="hidden">
      <Stack spacing={1}>
        <CardTitle>Become a delegate</CardTitle>
        <CardParagraph>
          State your position. Campaign to increase your voting power
        </CardParagraph>
      </Stack>
      <Stack
        align="center"
        bottom={0}
        direction="row"
        position="absolute"
        right={-28}
      >
        <Flex
          background="primary.50"
          borderTop="1px solid hsla(255, 100%, 92%, 1)"
          borderX="1px solid hsla(255, 100%, 92%, 1)"
          h="140px"
          p={4}
          px={5}
          roundedTop="10px"
          w="136px"
        >
          <Button
            border="1px solid hsla(252, 100%, 84%, 1)"
            color="hsla(252, 100%, 84%, 1)"
            fontSize="12px"
            size="sm"
            variant="tertiary"
            w="75px"
          >
            Delegate
          </Button>
        </Flex>
        <Stack
          background="primary.50"
          borderTop="1px solid hsla(255, 100%, 92%, 1)"
          borderX="1px solid hsla(255, 100%, 92%, 1)"
          height="140px"
          p={4}
          px={5}
          roundedTop="10px"
          spacing={3}
          w="368px"
        >
          <Flex justify="space-between" w="full">
            <Stack direction="row" spacing={3}>
              <Box
                background="hsla(255, 100%, 95%, 1)"
                h="2.375em"
                rounded="full"
                w="2.375em"
              />
              <Flex direction="column">
                <Text color="primary.300" fontSize="14px" lineHeight="20px">
                  Satoshi Nakamoto
                </Text>
                <Text color="primary.300" fontSize="12px" lineHeight="20px">
                  123.45K ENS
                </Text>
              </Flex>
            </Stack>
            <Button
              border="1px solid hsla(252, 100%, 84%, 1)"
              color="hsla(252, 100%, 84%, 1)"
              fontSize="12px"
              size="sm"
              variant="tertiary"
              w="75px"
            >
              Delegate
            </Button>
          </Flex>
          <Text color="primary.300" fontSize="14px" lineHeight="20px">
            I love ENS. I even made a proof of work ENS back in the day!
          </Text>
          <FadeMask gradientColor="hsla(256, 100%, 98%, 1)" height="141px" />
        </Stack>
        <Flex
          background="primary.50"
          borderTop="1px solid hsla(255, 100%, 92%, 1)"
          borderX="1px solid hsla(255, 100%, 92%, 1)"
          h="140px"
          p={4}
          px={5}
          roundedTop="10px"
          w="200px"
        >
          <Box
            background="hsla(255, 100%, 95%, 1)"
            h="2.375em"
            rounded="full"
            w="2.375em"
          />
        </Flex>
      </Stack>
    </Card>
  )
}

function Funding({ ...flexProps }: FlexProps) {
  return (
    <Card
      align={{ base: "flex-start", lg: "center" }}
      justify="space-between"
      {...flexProps}
    >
      <Stack spacing={1}>
        <CardTitle>Request funding</CardTitle>
        <CardParagraph>
          Have an idea for your DAO? Get paid to bring it to life
        </CardParagraph>
      </Stack>
      <Stack
        align="center"
        background="primary.50"
        borderTop="1px solid hsla(255, 100%, 92%, 1)"
        borderX="1px solid hsla(255, 100%, 92%, 1)"
        bottom={{ base: "0", lg: "unset" }}
        p={4}
        position="absolute"
        px={5}
        right={{ base: 4, lg: 10 }}
        roundedTop="10px"
        spacing={3}
        top={{ base: "unset", lg: "19px" }}
      >
        <Text color="primary.300">4.25 ETH</Text>
        <Stack align="center" direction="row" spacing={4}>
          <Stack
            bg="white"
            border="1px solid hsla(255, 100%, 92%, 1)"
            direction="row"
            px={4}
            py={2}
            rounded="40px"
            spacing={2}
          >
            <Icon as={Compound} h={4} w={4} />
            <Text color="primary.300" fontSize="12px">
              Treasury
            </Text>
          </Stack>
          <Icon as={ArrowRight} fill="primary.300" h={4} w={4} />
          <Stack
            bg="white"
            border="1px solid hsla(255, 100%, 92%, 1)"
            direction="row"
            px={4}
            py={2}
            rounded="40px"
            spacing={2}
          >
            <Icon as={Compound} h={4} w={4} />
            <Text color="primary.300" fontSize="12px">
              Grants
            </Text>
          </Stack>
        </Stack>
        <FadeMask gradientColor="hsla(256, 100%, 98%, 1)" height="105px" />
      </Stack>
    </Card>
  )
}

function Stat({
  children,
  ...stackProps
}: { children: ReactNode } & StackProps) {
  return (
    <Stack
      align="center"
      justify="center"
      px={{ base: 6, lg: 8 }}
      py={{ base: 6, lg: 0 }}
      spacing={2}
      {...stackProps}
    >
      {children}
    </Stack>
  )
}

function StatValue({ children }: { children: ReactNode }) {
  return (
    <Text
      color="neutral.700"
      fontSize="36px"
      fontWeight="bold"
      lineHeight="44px"
      textAlign="center"
    >
      {children}
    </Text>
  )
}

function StatTitle({ children }: { children: ReactNode }) {
  return (
    <Text
      color="neutral.600"
      flex={{ base: 1, lg: "unset" }}
      fontSize="20px"
      lineHeight="28px"
      textAlign="center"
    >
      {children}
    </Text>
  )
}

function FadeMask({
  gradientColor,
  mixBlendMode,
  ...boxProps
}: { gradientColor: string } & BoxProps) {
  return (
    <Box
      className="no-top-margin no-left-margin"
      insetBlockStart="-1px"
      insetX="-1px"
      position="absolute"
      {...boxProps}
    >
      <Box
        _after={{
          content: '""',
          position: "absolute",
          inset: 0,
          background: `linear-gradient(transparent, ${gradientColor})`,
          borderRadius: boxProps.rounded,
          pointerEvents: "none",
        }}
        h="full"
        mixBlendMode={mixBlendMode ?? "screen"}
        position="relative"
        rounded={boxProps.rounded}
        w="full"
      />
    </Box>
  )
}

enum GovernanceHeaderType {
  Compound = "COMPOUND",
  Ens = "ENS",
  Arbitrum = "ARBITRUM",
  Gitcoin = "GITCOIN",
  Uniswap = "UNISWAP",
  Hop = "HOP",
}

function GovernanceHeader({
  type,
  ...stackProps
}: { type: GovernanceHeaderType } & StackProps) {
  function getIcon(type: GovernanceHeaderType) {
    switch (type) {
      case GovernanceHeaderType.Arbitrum: {
        return PurpleArbitrum
      }
      case GovernanceHeaderType.Compound: {
        return PurpleCompound
      }
      case GovernanceHeaderType.Ens: {
        return PurpleEns
      }
      case GovernanceHeaderType.Gitcoin: {
        return PurpleGitcoin
      }
      case GovernanceHeaderType.Hop: {
        return PurpleHop
      }
      case GovernanceHeaderType.Uniswap: {
        return PurpleUniswap
      }
      default: {
        return undefined
      }
    }
  }

  function getDescription(type: GovernanceHeaderType) {
    switch (type) {
      case GovernanceHeaderType.Arbitrum: {
        return "Secure scaling for Ethereum"
      }
      case GovernanceHeaderType.Compound: {
        return "Compound is an algorithmic, autonomous interest rate protocol built for developers, to unlock a universe of open financial applications"
      }
      case GovernanceHeaderType.Ens: {
        return "Decentralized naming for wallets, websites, & more"
      }
      case GovernanceHeaderType.Gitcoin: {
        return "We enable communities to build and fund their shared needs. Help us build a regenerative crypto economic world"
      }
      case GovernanceHeaderType.Hop: {
        return "A protocol for sending tokens across rollups and their shared layer-1 network in a quick and trustless manner"
      }
      case GovernanceHeaderType.Uniswap: {
        return "Uniswap is a decentralized protocol for automated liquidity provision on Ethereum"
      }
      default: {
        return null
      }
    }
  }

  function getTitle(type: GovernanceHeaderType) {
    switch (type) {
      case GovernanceHeaderType.Arbitrum: {
        return "Arbitrum"
      }
      case GovernanceHeaderType.Compound: {
        return "Compound"
      }
      case GovernanceHeaderType.Ens: {
        return "ENS"
      }
      case GovernanceHeaderType.Gitcoin: {
        return "Gitcoin"
      }
      case GovernanceHeaderType.Hop: {
        return "Hop"
      }
      case GovernanceHeaderType.Uniswap: {
        return "Uniswap"
      }
      default: {
        return null
      }
    }
  }

  return (
    <Stack
      border="1px solid hsla(255, 100%, 92%, 1)"
      direction="column"
      p={4}
      position="relative"
      rounded="8px"
      spacing={4}
      w={{ base: "full", lg: "53.625em" }}
      zIndex={1}
      {...stackProps}
    >
      <Flex align="center" justify="flex-start">
        <Stack align="center" direction="row" spacing={4}>
          <Icon
            as={getIcon(type)}
            h={{ base: 8, lg: 10 }}
            w={{ base: 8, lg: 10 }}
          />
          <Text
            color="primary.400"
            fontSize={{ base: "14px", lg: "2xl" }}
            fontWeight="bold"
          >
            {getTitle(type)}
          </Text>
        </Stack>
      </Flex>
      <Text
        color="primary.500"
        fontSize="xs"
        fontWeight="medium"
        lineHeight="20px"
        w={{ base: "auto", lg: "34em" }}
      >
        {getDescription(type)}
      </Text>
      <Stack align="center" direction="row" spacing={1}>
        <Stack
          align="center"
          border="1px solid hsla(255, 100%, 95%, 1)"
          direction="row"
          h="20px"
          px={1}
          py={2}
          rounded="20px"
          spacing={1}
        >
          <Icon as={EthereumNetwork} fill="primary.500" h={3} w={2} />
          <Text color="primary.500" fontSize="2xs" fontWeight="medium">
            Ethereum
          </Text>
        </Stack>
        <Stack
          align="center"
          border="1px solid hsla(255, 100%, 95%, 1)"
          direction="row"
          h="20px"
          px={1}
          py={2}
          rounded="20px"
          spacing={1}
        >
          <Text color="primary.500" fontSize="2xs" fontWeight="medium">
            ERC-20
          </Text>
        </Stack>
        <Stack
          align="center"
          border="1px solid hsla(255, 100%, 95%, 1)"
          direction="row"
          h="20px"
          px={1}
          py={2}
          rounded="20px"
          spacing={1}
        >
          <Text color="primary.500" fontSize="2xs" fontWeight="medium">
            10.000 supply
          </Text>
        </Stack>
      </Stack>
      {/* these are the white corners for the cards needed because of the wrapping fade mask */}
      <Box
        bg="white"
        clipPath="polygon(92% 26%,92% 92%,72% 46%,27% 26%)"
        h={4}
        minW={4}
        mt="0 !important"
        position="absolute"
        right="-4px"
        top="-6px"
        transform="rotateY(20deg)"
        zIndex={1}
      />
      <Box
        bg="white"
        clipPath="polygon(92% 26%,92% 92%,72% 46%,27% 26%)"
        h={4}
        left="-13px"
        minW={8}
        mt="0 !important"
        position="absolute"
        top="-6px"
        transform="rotateY(110deg)"
        zIndex={1}
      />
      <Box
        bg="white"
        bottom="-6px"
        clipPath="polygon(92% 26%,92% 92%,72% 46%,27% 26%)"
        h={4}
        left="-3px"
        minW={4}
        mt="0 !important"
        position="absolute"
        transform="rotate(182deg)"
        zIndex={1}
      />
      <Box
        bg="white"
        bottom="-3px"
        clipPath="polygon(92% 26%,92% 92%,72% 46%,27% 26%)"
        h={4}
        minW={4}
        mt="0 !important"
        position="absolute"
        right="-7px"
        transform="rotate(91deg)"
        zIndex={1}
      />
    </Stack>
  )
}

export default Home

function ScrollSetter() {
  window.addEventListener(
    "scroll",
    () => {
      document.body.style.setProperty(
        "--scroll",
        String(
          window.pageYOffset /
            (document.body.offsetHeight - window.innerHeight),
        ),
      )
    },
    false,
  )

  return null
}

const PurpleCompound = createIcon({
  displayName: "PurpleCompound",
  viewBox: "0 0 90 90",
  path: (
    <g>
      <g filter="url(#filter0_d_1620_209)">
        <circle cx="45" cy="44" fill="white" r="42" />
        <path
          clipRule="evenodd"
          d="M27.2632 58.0826C26.0074 57.314 25.2388 55.949 25.2388 54.479V46.2722C25.2388 45.2978 26.0326 44.5082 27.007 44.5124C27.3178 44.5124 27.6244 44.5964 27.8932 44.7518L46.411 55.55C47.4946 56.18 48.1624 57.3392 48.1624 58.595V67.0958C48.1666 68.2634 47.2216 69.2126 46.054 69.2126C45.6634 69.2126 45.277 69.1034 44.9452 68.9018L27.2632 58.0826ZM54.8656 42.5048C55.9492 43.1348 56.6128 44.2982 56.617 45.5498V62.8034C56.617 63.3116 56.344 63.782 55.8988 64.0298L51.8458 66.3104C51.7954 66.3398 51.7408 66.3608 51.682 66.3776V56.7974C51.682 55.5584 51.031 54.4076 49.9642 53.7692L33.7018 44.042V33.227C33.7018 32.2526 34.4956 31.463 35.47 31.4672C35.7808 31.4672 36.0874 31.5512 36.3562 31.7066L54.8656 42.5048ZM62.9716 29.762C64.0594 30.392 64.7272 31.5554 64.7272 32.8112V58.0112C64.723 58.5278 64.4374 59.0024 63.9838 59.2502L60.1408 61.325V43.7816C60.1408 42.5426 59.4898 41.396 58.4314 40.7576L41.8078 30.7868V20.5304C41.8078 20.2196 41.8918 19.913 42.043 19.6442C42.5344 18.8042 43.6138 18.5186 44.4538 19.0058L62.9716 29.762Z"
          fill="hsla(248, 100%, 68%, 1)"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="90"
          id="filter0_d_1620_209"
          width="90"
          x="0"
          y="0"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.129412 0 0 0 0 0.105882 0 0 0 0 0.305882 0 0 0 0.15 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_1620_209"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1620_209"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </g>
  ),
})

const PurpleEns = createIcon({
  displayName: "PurpleEns",
  viewBox: "0 0 500 500",
  path: (
    <g>
      <path
        d="M141.423 163.173C144.492 157.476 148.92 152.619 154.319 149.031L244.912 86L152.088 238.931C152.088 238.931 143.978 225.275 140.814 218.365C136.873 209.677 134.885 200.236 134.99 190.702C135.095 181.168 137.291 171.772 141.423 163.173ZM104.034 268.669C105.057 283.294 109.204 297.532 116.198 310.43C123.191 323.329 132.87 334.589 144.586 343.458L244.79 413.075C244.79 413.075 182.096 323.054 129.217 233.477C123.863 224.013 120.264 213.666 118.592 202.931C117.852 198.07 117.852 193.125 118.592 188.264C117.213 190.81 114.537 196.022 114.537 196.022C109.175 206.917 105.523 218.568 103.709 230.568C102.665 243.252 102.774 256.004 104.034 268.669ZM359.512 280.79C356.267 273.881 348.238 260.224 348.238 260.224L255.577 413.075L346.17 350.084C351.568 346.496 355.997 341.64 359.066 335.943C363.198 327.343 365.393 317.947 365.499 308.414C365.604 298.88 363.616 289.438 359.674 280.75L359.512 280.79ZM396.292 230.446C395.269 215.822 391.122 201.583 384.129 188.685C377.135 175.787 367.456 164.526 355.74 155.658L255.698 86C255.698 86 318.351 176.021 371.272 265.598C376.611 275.065 380.196 285.412 381.856 296.144C382.596 301.005 382.596 305.95 381.856 310.811C383.235 308.265 385.911 303.053 385.911 303.053C391.273 292.158 394.925 280.507 396.738 268.507C397.796 255.824 397.701 243.072 396.455 230.406L396.292 230.446Z"
        fill="hsla(248, 100%, 68%, 1)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_36_20"
          x1="299.139"
          x2="-34.9127"
          y1="451.839"
          y2="-77.5173"
        >
          <stop stopColor="#44BCF0" />
          <stop offset="0.378795" stopColor="#7298F8" />
          <stop offset="1" stopColor="#A099FF" />
        </linearGradient>
      </defs>
    </g>
  ),
})

const PurpleArbitrum = createIcon({
  displayName: "PurpleArbitrum",
  viewBox: "0 0 198.042 178.453",
  path: (
    <g
      data-name="arbirtum logo"
      id="arbirtum_logo"
      transform="translate(-856.479 -718)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 1" transform="translate(892.902 717.726)">
        <path
          d="M103.926,0a20,20,0,0,1,17.341,10.035l27.007,47a20,20,0,0,1,0,19.929l-27.007,47A20,20,0,0,1,103.926,134H50.074a20,20,0,0,1-17.341-10.035l-27.007-47a20,20,0,0,1,0-19.929l27.007-47A20,20,0,0,1,50.074,0Z"
          data-name="Polygon 1"
          fill="hsla(248, 100%, 68%, 1)"
          id="Polygon_1"
          transform="translate(134.098 0.274) rotate(90)"
        />
      </g>
      <g id="arbitrum" transform="translate(-1311.275 228.562)">
        <path
          d="M2332.2,502.9,2285.8,529.7a11.6,11.6,0,0,0-5.8,10.05l.029,53.659a11.6,11.6,0,0,0,5.794,10.035l46.454,26.854a11.6,11.6,0,0,0,11.6,0l46.4-26.792a11.6,11.6,0,0,0,5.8-10.05l-.029-53.659a11.6,11.6,0,0,0-5.794-10.034L2343.8,502.908a11.6,11.6,0,0,0-11.6,0Z"
          data-name="Path 83"
          fill="#fff"
          id="Path_83"
          transform="translate(-66.718 -0.217)"
        />

        <path
          d="M2328.247,623.553a2.171,2.171,0,0,1,.456-.951q20.82-35.328,41.647-70.653c.193-.327.372-.663.536-.956-.122-.213-.261-.169-.379-.169-5.932.021-11.864.059-17.8.052a1.242,1.242,0,0,0-1.179.79q-6.712,11.1-13.441,22.194-14.255,23.519-28.513,47.037c-.084.139-.172.275-.247.418-.168.324-.359.375-.7.177-1.475-.871-2.966-1.714-4.533-2.615l42.3-67.842c-.091-.276-.278-.207-.422-.212-2.267-.068-4.535-.166-6.8-.186a25.133,25.133,0,0,0-9.344,1.506,13.194,13.194,0,0,0-6.586,5.347q-11.593,18.082-23.274,36.108c-.122.19-.252.376-.378.564-.446,0-.38-.368-.421-.625a7.519,7.519,0,0,1-.028-1.165c0-13.292.046-26.584-.041-39.875a5.732,5.732,0,0,1,3.282-5.536c10.221-5.827,20.376-11.774,30.564-17.66,4.547-2.627,9.108-5.229,13.622-7.911a5.153,5.153,0,0,1,5.742,0c8.3,4.871,16.674,9.629,25.017,14.431q9.439,5.435,18.887,10.851c.451.257.89.532,1.343.785a4.369,4.369,0,0,1,2.312,4.1q.018,21.688,0,43.376a2.807,2.807,0,0,1-.114,1.055c-.5.483-.681-.059-.863-.316-.75-1.055-1.423-2.166-2.12-3.258q-8.372-13.105-16.8-26.175c-1.888-2.945-3.8-5.878-5.668-8.835-.436-.69-.515-.7-.951.041q-4.228,7.154-8.451,14.311a1.454,1.454,0,0,0,.172,1.7q3.482,5.618,6.951,11.244l11.857,19.18q2.4,3.888,4.808,7.769a1.965,1.965,0,0,1,.474,1.244c-.321.638-.99.817-1.547,1.107a11.693,11.693,0,0,1-2.983,1.557c-.574-.143-.777-.661-1.049-1.085-3.671-5.726-7.295-11.481-10.942-17.221-3.751-5.9-7.484-11.822-11.251-17.716-.483-.756-.56-.761-1.014,0-3.277,5.513-6.509,11.052-9.794,16.559a1.468,1.468,0,0,0,.081,1.714c4.425,6.82,8.748,13.7,13.126,20.554,1.221,1.91,2.451,3.813,3.65,5.737.234.374.554.747.342,1.253a20.777,20.777,0,0,1-3.7,2.3q-6.134,3.569-12.274,7.128a4.056,4.056,0,0,1-4.4,0q-9.044-5.232-18.121-10.417A2.362,2.362,0,0,1,2328.247,623.553Z"
          data-name="Path 84"
          fill="hsla(248, 100%, 68%, 1)"
          id="Path_84"
          transform="translate(-78.08 -11.609)"
        />
        <path
          d="M2388.3,584.524c.009-.357.027-.713.027-1.069q0-21.639.014-43.276a4.5,4.5,0,0,0-2.458-4.277q-22.722-13.026-45.411-26.111a4.216,4.216,0,0,0-4.58-.012q-12.6,7.323-25.232,14.608-10.135,5.867-20.269,11.737a4.221,4.221,0,0,0-2.271,3.98q.052,20.763.059,41.526c0,.308-.125.651.144.923-1.6,2.664-3.358,5.224-5.043,7.831q-1.266,1.958-2.542,3.909c-.136.208-.293.4-.562.771-.02-.436-.041-.677-.041-.917q0-27.381.01-54.762a11.032,11.032,0,0,1,5.764-9.937c5.874-3.488,11.826-6.846,17.739-10.272,4.989-2.889,9.961-5.8,14.947-8.694,4.65-2.7,9.253-5.481,13.982-8.034a11.576,11.576,0,0,1,11.781.306c3.8,2.156,7.584,4.352,11.373,6.53q5.346,3.077,10.691,6.158,4.926,2.836,9.855,5.666,4.887,2.807,9.775,5.611c1.432.823,2.863,1.647,4.286,2.485a12.02,12.02,0,0,1,5.95,8.562,8.645,8.645,0,0,1,.062,1.553q0,26.943.007,53.887a11.572,11.572,0,0,1-4.162,8.952,13.867,13.867,0,0,1-2.4,1.471q-7.3,4.259-14.621,8.483-7.779,4.5-15.565,8.985-7.481,4.334-14.946,8.7a11.106,11.106,0,0,1-4.1,1.691,11.561,11.561,0,0,1-7.2-.916c-2.482-1.331-4.9-2.775-7.347-4.177q-4.929-2.828-9.853-5.667-1.391-.8-2.784-1.591c-.341-.194-.574-.391-.308-.836,1.228-2.057,2.437-4.126,3.659-6.187.043-.075.153-.11.232-.165a28.636,28.636,0,0,1,3.3,1.815q8.043,4.6,16.061,9.245a3.789,3.789,0,0,0,4.118-.044c5.077-2.979,10.18-5.915,15.272-8.866.252-.146.51-.279.766-.417a4.2,4.2,0,0,1,1.852-1.351c4.6-2.636,9.166-5.324,13.765-7.953.384-.219.73-.557,1.23-.5l4.506-2.61c.275-.576.862-.762,1.354-1.046,2.267-1.309,4.513-2.654,6.8-3.921a3.886,3.886,0,0,0,2.069-3.674c.023-2.294,0-4.589.011-6.884A1.689,1.689,0,0,1,2388.3,584.524Z"
          data-name="Path 85"
          fill="hsla(248, 100%, 68%, 1)"
          id="Path_85"
          transform="translate(-66.804)"
        />

        <path
          d="M2495.207,648.594c.012,2.848.075,5.7.011,8.544a3.61,3.61,0,0,1-1.889,3.27c-2.868,1.624-5.684,3.34-8.578,4.92-1.151-2.1-2.48-4.1-3.738-6.14q-9.8-15.884-19.622-31.75c-.392-.634-.751-1.292-1.189-1.891a.958.958,0,0,1,0-1.223c1.563-2.614,3.1-5.243,4.647-7.868l4.294-7.284a.817.817,0,0,1,.379-.4c.747,1.151,1.5,2.312,2.252,3.474q6.776,10.55,13.549,21.1,4.593,7.158,9.185,14.317A2.913,2.913,0,0,0,2495.207,648.594Z"
          data-name="Path 90"
          fill="hsla(248, 100%, 68%, 1)"
          id="Path_90"
          transform="translate(-173.706 -64.071)"
        />
        <path
          d="M2459.274,700.394c-1.865,1.224-3.843,2.265-5.765,3.4-3.485,2.049-6.992,4.06-10.489,6.086-.2.113-.4.219-.593.328a1.651,1.651,0,0,0-.4-.871q-4.671-7.341-9.343-14.679-3.839-6.026-7.7-12.04a1.237,1.237,0,0,1-.068-1.5q5.161-8.692,10.279-17.41c.081-.134.146-.29.347-.333.28.063.348.343.481.552q9.3,14.649,18.6,29.3c1.319,2.079,2.632,4.163,3.959,6.238a3.076,3.076,0,0,0,.69.931Z"
          data-name="Path 91"
          fill="hsla(248, 100%, 68%, 1)"
          id="Path_91"
          transform="translate(-152.735 -96.527)"
        />
      </g>
    </g>
  ),
})

const PurpleHop = createIcon({
  displayName: "PurpleHop",
  viewBox: "0 0 500 482.97",
  path: (
    <g>
      <path
        d="M477.67,160,376.05,12.17C360.09-11,324,1.3,325.18,29.45c1.08,25.66,10.67,52.82,33.62,86.15a.1.1,0,0,1-.13.14c-9.39-7-150.94-113.3-319.2-56.7l-.19,0C20.71,66,11.23,81.19,15.93,99,20.28,115.49,37.19,125.39,54,122.6c35.74-5.95,71.21-8.78,106.09-5.72s69,12,100.77,27.61,60.55,37.7,87,63c29.75,28.43,66.66,89.06,66.66,89.06A18.71,18.71,0,0,0,437.09,305c10-3.58,14.66-15.11,10.9-25a387.07,387.07,0,0,0-28.69-59.67.1.1,0,0,1,.13-.15c12.44,4.68,23.95,10.51,37.57,7.32,11.11-2.61,20.86-10.17,25.39-20.75A48.07,48.07,0,0,0,477.67,160Z"
        fill="hsla(248, 100%, 68%, 1)"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        d="M8.68,322.24,110.87,470.9c16,23.24,51.42,10.5,50.33-17.68-1-25.78-10.57-53.05-33.63-86.55-.07-.1,0-.23.12-.16,9.41,7,151,113.29,319.19,56.7a1.46,1.46,0,0,0,.2,0c18.57-6.94,28-22.1,23.34-39.93-4.34-16.48-21.25-26.38-38.05-23.58-35.75,6-71.21,8.77-106.1,5.71s-69-12-100.76-27.6-60.55-37.71-87-63c-29.74-28.43-66.66-89.06-66.66-89.06a18.69,18.69,0,0,0-23.47-8.12c-9.52,4-13.59,15.25-9.94,24.89A386.94,386.94,0,0,0,67.06,262a.11.11,0,0,1-.13.16c-12.44-4.68-24-10.51-37.57-7.32C18.25,257.4,8.49,265,4,275.55A48.07,48.07,0,0,0,8.68,322.24Z"
        fill="hsla(248, 100%, 68%, 1)"
        xmlns="http://www.w3.org/2000/svg"
      />
    </g>
  ),
})

const PurpleGitcoin = createIcon({
  displayName: "PurpleGitcoin",
  viewBox: "0 0 100 117",
  path: (
    <g>
      <path
        d="M80.8741935,87.8684211 L74.716129,82.1877632 C79.2516129,77.7338684 81.7483871,71.8622368 81.7483871,65.6572105 C81.754231,61.8813819 80.8194519,58.1639665 79.0290323,54.8429211 C77.7673872,52.5131342 76.1289783,50.4097151 74.1806452,48.6184737 L70.9677419,45.6470526 L74.4129032,42.5526316 L77.3483871,45.5143421 L80.1548387,42.7630263 C86.7096774,48.8256316 90.3225806,56.9565789 90.3225806,65.6572105 C90.3225806,73.9953158 86.9677419,81.8835 80.8741935,87.8684211 Z"
        fill="hsla(248, 100%, 68%, 1)"
        id="Path"
        stroke="#0FCE7C"
        strokeWidth="0.5"
      />
      <path
        d="M80.6741935,107.742632 L50.9645161,107.742632 C27.8903226,107.742632 9.11290323,88.6614474 9.11290323,65.2105263 C9.11290323,41.7563684 27.8870968,22.6784211 50.9709677,22.6784211 C60.5516129,22.6784211 69.6419355,25.9282105 77.0225806,31.9131316 L56.6290323,51.0622895 C54.8351657,50.3168099 52.9125943,49.9329599 50.9709677,49.9326316 C42.6806452,49.9326316 35.9387097,56.7882632 35.9387097,65.2105263 C35.9387097,73.6327895 42.6806452,80.4884211 50.9709677,80.4884211 C52.4099832,80.4873707 53.8411605,80.2758307 55.2193548,79.8604737 L80.6774194,99.3333158 L80.6774194,107.742632 L80.6741935,107.742632 Z M50.9645161,71.2310526 C47.7,71.2310526 45.0419355,68.5282895 45.0419355,65.2105263 C45.0419355,61.8895263 47.7,59.19 50.9677419,59.19 C54.2322581,59.19 56.8903226,61.8895263 56.8903226,65.2105263 C56.8903226,68.5282895 54.2322581,71.2310526 50.9677419,71.2310526 L50.9645161,71.2310526 Z M66,65.2105263 C66,62.2132105 65.1419355,59.4165789 63.6677419,57.0536842 L75.8290323,45.6308684 L75.9322581,45.7409211 L90.3225806,31.9616842 L87.0064516,28.5921316 C77.3806452,18.8071579 64.5806452,13.4210526 50.9677419,13.4210526 C22.8645161,13.4210526 0,36.6551053 0,65.2105263 C0,93.4843421 22.4193548,116.527421 50.1387097,116.977342 L50.1387097,117 L89.783871,117 L89.783871,94.7143421 L63.0580645,74.2736842 C64.974206,71.6433912 66.0047485,68.4686211 66,65.2105263 Z"
        fill="hsla(248, 100%, 68%, 1)"
        fillRule="nonzero"
        id="helmet"
      />
      <path
        d="M51,71 C54.3137085,71 57,68.3137085 57,65 C57,61.6862915 54.3137085,59 51,59 C47.6862915,59 45,61.6862915 45,65 C45,68.3137085 47.6862915,71 51,71 Z M51,80 C59.2842712,80 66,73.2842712 66,65 C66,56.7157288 59.2842712,50 51,50 C42.7157288,50 36,56.7157288 36,65 C36,73.2842712 42.7157288,80 51,80 Z"
        fill="hsla(248, 100%, 68%, 1)"
        id="center-circle"
      />
      <polygon
        fill="hsla(248, 100%, 68%, 1)"
        fillRule="nonzero"
        id="particle2"
        points="100 10.1842105 96.7741935 13.4210526 93.5483871 10.1842105 96.7741935 6.94736842"
      />
      <polygon
        fill="hsla(248, 100%, 68%, 1)"
        fillRule="nonzero"
        id="particle1"
        points="74.1935484 10.1842105 70.9677419 13.4210526 67.7419355 10.1842105 70.9677419 6.94736842"
      />
      <path
        d="M35.483871,45.7894737 C33.7032258,45.7894737 32.2580645,44.184 32.2580645,42.2030526 L32.2580645,4.06010526 C32.2580645,2.07915789 33.7032258,0.473684211 35.483871,0.473684211 C37.2645161,0.473684211 38.7096774,2.07915789 38.7096774,4.06010526 L38.7096774,42.2030526 C38.7096774,44.184 37.2645161,45.7894737 35.483871,45.7894737"
        fill="hsla(248, 100%, 68%, 1)"
        fillRule="nonzero"
        id="ant1"
      />
      <path
        d="M54.483871,37.4736842 C52.7032258,37.4736842 51.2580645,36.1628271 51.2580645,34.5453985 L51.2580645,3.40196992 C51.2580645,1.78454135 52.7032258,0.473684211 54.483871,0.473684211 C56.2645161,0.473684211 57.7096774,1.78454135 57.7096774,3.40196992 L57.7096774,34.5453985 C57.7096774,36.1628271 56.2645161,37.4736842 54.483871,37.4736842"
        fill="hsla(248, 100%, 68%, 1)"
        fillRule="nonzero"
        id="ant2"
      />
    </g>
  ),
})

const PurpleUniswap = createIcon({
  displayName: "PurpleUniswap",
  viewBox: "0 0 500 500",
  path: (
    <g>
      <path
        d="M224.534 123.226C218.692 122.32 218.445 122.213 221.195 121.791C226.464 120.98 238.905 122.085 247.479 124.123C267.494 128.881 285.707 141.069 305.148 162.714L310.313 168.465L317.701 167.277C348.828 162.275 380.493 166.25 406.978 178.485C414.264 181.851 425.752 188.552 427.187 190.274C427.645 190.822 428.485 194.355 429.053 198.124C431.02 211.164 430.036 221.16 426.047 228.625C423.877 232.688 423.756 233.975 425.215 237.452C426.38 240.227 429.627 242.28 432.843 242.276C439.425 242.267 446.509 231.627 449.791 216.823L451.095 210.943L453.678 213.868C467.846 229.92 478.974 251.811 480.885 267.393L481.383 271.455L479.002 267.762C474.903 261.407 470.785 257.08 465.512 253.591C456.006 247.301 445.955 245.161 419.337 243.758C395.296 242.491 381.69 240.438 368.198 236.038C345.244 228.554 333.672 218.587 306.405 182.812C294.294 166.923 286.808 158.131 279.362 151.051C262.442 134.964 245.816 126.527 224.534 123.226Z"
        fill="hsla(248, 100%, 68%, 1)"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        d="M432.61 158.704C433.215 148.057 434.659 141.033 437.562 134.62C438.711 132.081 439.788 130.003 439.954 130.003C440.12 130.003 439.621 131.877 438.844 134.167C436.733 140.392 436.387 148.905 437.84 158.811C439.686 171.379 440.735 173.192 454.019 186.769C460.25 193.137 467.497 201.168 470.124 204.616L474.901 210.886L470.124 206.405C464.282 200.926 450.847 190.24 447.879 188.712C445.89 187.688 445.594 187.705 444.366 188.927C443.235 190.053 442.997 191.744 442.84 199.741C442.596 212.204 440.897 220.204 436.797 228.203C434.58 232.529 434.23 231.606 436.237 226.723C437.735 223.077 437.887 221.474 437.876 209.408C437.853 185.167 434.975 179.339 418.097 169.355C413.821 166.826 406.776 163.178 402.442 161.249C398.107 159.32 394.664 157.639 394.789 157.514C395.267 157.038 411.727 161.842 418.352 164.39C428.206 168.181 429.833 168.672 431.03 168.215C431.832 167.909 432.22 165.572 432.61 158.704Z"
        fill="hsla(248, 100%, 68%, 1)"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        d="M235.883 200.175C224.022 183.846 216.684 158.809 218.272 140.093L218.764 134.301L221.463 134.794C226.534 135.719 235.275 138.973 239.369 141.459C250.602 148.281 255.465 157.263 260.413 180.328C261.862 187.083 263.763 194.728 264.638 197.317C266.047 201.483 271.369 211.214 275.696 217.534C278.813 222.085 276.743 224.242 269.853 223.62C259.331 222.67 245.078 212.834 235.883 200.175Z"
        fill="hsla(248, 100%, 68%, 1)"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        d="M418.223 321.707C362.793 299.389 343.271 280.017 343.271 247.331C343.271 242.521 343.437 238.585 343.638 238.585C343.84 238.585 345.985 240.173 348.404 242.113C359.644 251.128 372.231 254.979 407.076 260.062C427.58 263.054 439.119 265.47 449.763 269C483.595 280.22 504.527 302.99 509.518 334.004C510.969 343.016 510.118 359.915 507.766 368.822C505.91 375.857 500.245 388.537 498.742 389.023C498.325 389.158 497.917 387.562 497.81 385.389C497.24 373.744 491.355 362.406 481.472 353.913C470.235 344.257 455.137 336.569 418.223 321.707Z"
        fill="hsla(248, 100%, 68%, 1)"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        d="M379.31 330.978C378.615 326.846 377.411 321.568 376.633 319.25L375.219 315.036L377.846 317.985C381.481 322.065 384.354 327.287 386.789 334.241C388.647 339.549 388.856 341.127 388.842 349.753C388.828 358.221 388.596 359.996 386.88 364.773C384.174 372.307 380.816 377.649 375.181 383.383C365.056 393.688 352.038 399.393 333.253 401.76C329.987 402.171 320.47 402.864 312.103 403.299C291.016 404.395 277.138 406.661 264.668 411.04C262.875 411.67 261.274 412.052 261.112 411.89C260.607 411.388 269.098 406.326 276.111 402.948C285.999 398.185 295.842 395.586 317.897 391.913C328.792 390.098 340.043 387.897 342.9 387.021C369.88 378.749 383.748 357.402 379.31 330.978Z"
        fill="hsla(248, 100%, 68%, 1)"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        d="M404.719 376.105C397.355 360.273 395.664 344.988 399.698 330.732C400.13 329.209 400.824 327.962 401.242 327.962C401.659 327.962 403.397 328.902 405.103 330.05C408.497 332.335 415.303 336.182 433.437 346.069C456.065 358.406 468.966 367.959 477.74 378.873C485.423 388.432 490.178 399.318 492.467 412.593C493.762 420.113 493.003 438.206 491.074 445.778C484.99 469.653 470.85 488.406 450.682 499.349C447.727 500.952 445.075 502.269 444.788 502.275C444.501 502.28 445.577 499.543 447.18 496.191C453.965 482.009 454.737 468.214 449.608 452.859C446.467 443.457 440.064 431.985 427.135 412.596C412.103 390.054 408.417 384.054 404.719 376.105Z"
        fill="hsla(248, 100%, 68%, 1)"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        d="M196.519 461.525C217.089 444.157 242.682 431.819 265.996 428.032C276.043 426.399 292.78 427.047 302.084 429.428C316.998 433.245 330.338 441.793 337.276 451.978C344.057 461.932 346.966 470.606 349.995 489.906C351.189 497.519 352.489 505.164 352.882 506.895C355.156 516.897 359.583 524.892 365.067 528.907C373.779 535.283 388.78 535.68 403.536 529.924C406.041 528.947 408.215 528.271 408.368 528.424C408.903 528.955 401.473 533.93 396.23 536.548C389.177 540.071 383.568 541.434 376.115 541.434C362.6 541.434 351.379 534.558 342.016 520.539C340.174 517.78 336.032 509.516 332.813 502.176C322.928 479.628 318.046 472.759 306.568 465.242C296.579 458.701 283.697 457.53 274.006 462.282C261.276 468.523 257.724 484.791 266.842 495.101C270.465 499.198 277.223 502.732 282.749 503.419C293.086 504.705 301.97 496.841 301.97 486.404C301.97 479.627 299.365 475.76 292.808 472.801C283.852 468.76 274.226 473.483 274.272 481.897C274.292 485.484 275.854 487.737 279.45 489.364C281.757 490.408 281.811 490.491 279.929 490.1C271.712 488.396 269.787 478.49 276.394 471.913C284.326 464.018 300.729 467.502 306.362 478.279C308.728 482.805 309.003 491.82 306.94 497.264C302.322 509.448 288.859 515.855 275.201 512.368C265.903 509.994 262.117 507.424 250.906 495.876C231.425 475.809 223.862 471.92 195.777 467.536L190.395 466.696L196.519 461.525Z"
        fill="hsla(248, 100%, 68%, 1)"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        clipRule="evenodd"
        d="M49.6202 12.0031C114.678 90.9638 214.977 213.901 219.957 220.784C224.068 226.467 222.521 231.576 215.478 235.58C211.561 237.807 203.508 240.063 199.476 240.063C194.916 240.063 189.779 237.867 186.038 234.318C183.393 231.81 172.721 215.874 148.084 177.646C129.233 148.396 113.457 124.131 113.027 123.725C112.032 122.785 112.049 122.817 146.162 183.854C167.582 222.181 174.813 235.731 174.813 237.543C174.813 241.229 173.808 243.166 169.261 248.238C161.681 256.694 158.293 266.195 155.847 285.859C153.104 307.902 145.394 323.473 124.026 350.122C111.518 365.722 109.471 368.581 106.315 374.869C102.339 382.786 101.246 387.221 100.803 397.219C100.335 407.79 101.247 414.619 104.477 424.726C107.304 433.575 110.255 439.417 117.8 451.104C124.311 461.188 128.061 468.683 128.061 471.614C128.061 473.947 128.506 473.95 138.596 471.672C162.741 466.219 182.348 456.629 193.375 444.877C200.199 437.603 201.801 433.586 201.853 423.618C201.887 417.098 201.658 415.733 199.896 411.982C197.027 405.877 191.804 400.801 180.292 392.932C165.209 382.621 158.767 374.32 156.987 362.904C155.527 353.537 157.221 346.928 165.565 329.44C174.202 311.338 176.342 303.624 177.79 285.378C178.725 273.589 180.02 268.94 183.407 265.209C186.939 261.317 190.119 260 198.861 258.805C213.113 256.858 222.188 253.171 229.648 246.297C236.119 240.334 238.827 234.588 239.243 225.938L239.558 219.382L235.942 215.166C222.846 199.896 40.85 0 40.044 0C39.8719 0 44.1813 5.40178 49.6202 12.0031ZM135.412 409.18C138.373 403.937 136.8 397.195 131.847 393.902C127.167 390.79 119.897 392.256 119.897 396.311C119.897 397.548 120.582 398.449 122.124 399.243C124.72 400.579 124.909 402.081 122.866 405.152C120.797 408.262 120.964 410.996 123.337 412.854C127.162 415.849 132.576 414.202 135.412 409.18Z"
        fill="hsla(248, 100%, 68%, 1)"
        fillRule="evenodd"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        clipRule="evenodd"
        d="M248.552 262.244C241.862 264.299 235.358 271.39 233.344 278.826C232.116 283.362 232.813 291.319 234.653 293.776C237.625 297.745 240.499 298.791 248.282 298.736C263.518 298.63 276.764 292.095 278.304 283.925C279.567 277.229 273.749 267.948 265.736 263.874C261.601 261.772 252.807 260.938 248.552 262.244ZM266.364 276.172C268.714 272.834 267.686 269.225 263.69 266.785C256.08 262.138 244.571 265.983 244.571 273.173C244.571 276.752 250.572 280.656 256.074 280.656C259.735 280.656 264.746 278.473 266.364 276.172Z"
        fill="hsla(248, 100%, 68%, 1)"
        fillRule="evenodd"
        xmlns="http://www.w3.org/2000/svg"
      />
    </g>
  ),
})

const DAO_LOGOS = [
  <Image
    key="dao-arbitrum"
    alt="Arbitrum"
    borderRadius="full"
    boxSize="60px"
    src="/images/Arbitrum_Logo.webp"
  />,
  <Image
    key="dao-uniswap"
    alt="Uniswap"
    borderRadius="full"
    boxSize="60px"
    src="/images/Uniswap_Logo.webp"
  />,
  <Image
    key="dao-compound"
    alt="Compound"
    borderRadius="full"
    boxSize="60px"
    src="/images/Compound_Logo.webp"
  />,
  <Image
    key="dao-hop"
    alt="Hop"
    borderRadius="full"
    boxSize="60px"
    src="/images/Hop_Logo.webp"
  />,
  <Image
    key="dao-nouns"
    alt="Nouns"
    borderRadius="full"
    boxSize="60px"
    src="/images/Nouns_Logo.webp"
  />,
  <Image
    key="dao-ens"
    alt="ENS"
    borderRadius="full"
    boxSize="60px"
    src="/images//ENS_Logo.webp"
  />,
  <Image
    key="dao-optimism"
    alt="Optimism"
    borderRadius="full"
    boxSize="60px"
    src="/images/Optimism_Logo.webp"
  />,
  <Image
    key="dao-aave"
    alt="AAVE"
    borderRadius="full"
    boxSize="60px"
    src="/images/AAVE_Logo.webp"
  />,
  <Image
    key="dao-rari"
    alt="Rari"
    borderRadius="full"
    boxSize="60px"
    src="/images/Rari_Logo.webp"
  />,
  <Image
    key="dao-unlock"
    alt="Unlock"
    borderRadius="full"
    boxSize="60px"
    src="/images/Unlock_Logo.webp"
  />,
  <Image
    key="dao-gitcoin"
    alt="Gitcoin"
    borderRadius="full"
    boxSize="60px"
    src="/images/Gitcoin_Logo.webp"
  />,
]

const BACKERS = [
  <Image
    key="backer-bcap"
    alt="Blockchain Capital"
    filter="grayscale(100%)"
    src="/images/bcap.png"
    width={160}
  />,
  <Image
    key="backer-placeholder"
    alt="Placeholder Capital"
    filter="grayscale(100%)"
    src="/images/placeholder.png"
    width={160}
  />,
  <Image
    key="backer-notation"
    alt="Notation Capital"
    filter="grayscale(100%)"
    src="/images/Notation.png"
    width={160}
  />,
  <Image
    key="backer-castleisland"
    alt="Castle Island"
    filter="grayscale(100%)"
    src="/images/CastleIsland.png"
    width={160}
  />,
  <Image
    key="backer-lemniscap"
    alt="Lemniscap"
    filter="grayscale(100%)"
    src="/images/Lemniscap.png"
    width={160}
  />,
  <Image
    key="backer-collab-currency"
    alt="Collab+Currency"
    filter="grayscale(100%)"
    src="/images/collab-currency.png"
    width={160}
  />,
  <Image
    key="backer-1kx"
    alt="1kx"
    filter="grayscale(100%)"
    src="/images/1kx.png"
    width={140}
  />,
  <Image
    key="backer-metacartel"
    alt="Metacartel Ventures"
    filter="grayscale(100%)"
    src="/images/Metacartel.png"
    width={120}
  />,
]

export const getServerSideProps: GetServerSideProps = async (context) => {
  const origin = context.req.headers.host

  if (!origin) {
    return {
      props: {},
    }
  }

  if (isWhitelabel(context)) {
    return {
      redirect: {
        permanent: false,
        destination: getWhiteLabelRoute(
          // @ts-expect-error nothing to do
          ROUTES.governance.id(WHITELABEL_GOVERNORS_IDS[origin]),
        ),
      },
    }
  }

  return {
    props: {},
  }
}
